(function(){
    const sliders = [...document.querySelectorAll('.slider-blog__body')];

    const arrowNext = document.querySelector('#next');
    const arrowBefore = document.querySelector('#before');
    let value;

    arrowNext.addEventListener('click', ()=>changePosition(1));
    arrowBefore.addEventListener('click', ()=>changePosition(-1));

    function changePosition(change) {

        const currentElement = Number(document.querySelector('.slider-blog__body--show').dataset.id);

        value = currentElement;
        value+= change;

        console.log(sliders.length);

        if(value === 0 || value == sliders.length+1) {
            value = value === 0 ? sliders.length : 1;
        }

        sliders[currentElement-1].classList.toggle('slider-blog__body--show');
        sliders[value-1].classList.toggle('slider-blog__body--show');
        
    }
    
}) ();


(function(){
    const sliderCorporativo = [...document.querySelectorAll('.slider-blog-corporativo__body')];

    const arrowNextCorporativo = document.querySelector('#nextCorporativo');
    const arrowBeforeCorporativo = document.querySelector('#beforeCorporativo');
    let value;

    arrowNextCorporativo.addEventListener('click', ()=>changePosition(1));
    arrowBeforeCorporativo.addEventListener('click', ()=>changePosition(-1));

    function changePosition(change) {

        const currentElementCorporativo = Number(document.querySelector('.slider-blog-corporativo__body--show').dataset.id);

        value = currentElementCorporativo;
        value+= change;

        console.log(sliderCorporativo.length);

        if(value === 0 || value == sliderCorporativo.length+1) {
            value = value === 0 ? sliderCorporativo.length : 1;
        }

        sliderCorporativo[currentElementCorporativo-1].classList.toggle('slider-blog-corporativo__body--show');
        sliderCorporativo[value-1].classList.toggle('slider-blog-corporativo__body--show');
        
    }

    setInterval(function(){
        changePosition(1);
    },5000 );

    
    
}) ();

(function(){
    const sliderResidencial = [...document.querySelectorAll('.slider-blog-residencial__body')];

    const arrowNextResidencial = document.querySelector('#nextResidencial');
    const arrowBeforeResidencial = document.querySelector('#beforeResidencial');
    let value;

    arrowNextResidencial.addEventListener('click', ()=>changePosition(1));
    arrowBeforeResidencial.addEventListener('click', ()=>changePosition(-1));

    function changePosition(change) {

        const currentElementResidencial = Number(document.querySelector('.slider-blog-residencial__body--show').dataset.id);

        value = currentElementResidencial;
        value+= change;

        console.log(sliderResidencial.length);

        if(value === 0 || value == sliderResidencial.length+1) {
            value = value === 0 ? sliderResidencial.length : 1;
        }

        sliderResidencial[currentElementResidencial-1].classList.toggle('slider-blog-residencial__body--show');
        sliderResidencial[value-1].classList.toggle('slider-blog-residencial__body--show');
        
    }

    setInterval(function(){
        changePosition(1);
    },5000 );
    
}) ();

(function(){
    const sliderEducativo = [...document.querySelectorAll('.slider-blog-educativo__body')];

    const arrowNextEducativo = document.querySelector('#nextEducativo');
    const arrowBeforeEducativo = document.querySelector('#beforeEducativo');
    let value;

    arrowNextEducativo.addEventListener('click', ()=>changePosition(1));
    arrowBeforeEducativo.addEventListener('click', ()=>changePosition(-1));

    function changePosition(change) {

        const currentElementEducativo = Number(document.querySelector('.slider-blog-educativo__body--show').dataset.id);

        value = currentElementEducativo;
        value+= change;

        console.log(sliderEducativo.length);

        if(value === 0 || value == sliderEducativo.length+1) {
            value = value === 0 ? sliderEducativo.length : 1;
        }

        sliderEducativo[currentElementEducativo-1].classList.toggle('slider-blog-educativo__body--show');
        sliderEducativo[value-1].classList.toggle('slider-blog-educativo__body--show');
        
    }
    
}) ();

(function(){
    const sliderRetail = [...document.querySelectorAll('.slider-blog-retail__body')];

    const arrowNextRetail = document.querySelector('#nextRetail');
    const arrowBeforeRetail = document.querySelector('#beforeRetail');
    let value;

    arrowNextRetail.addEventListener('click', ()=>changePosition(1));
    arrowBeforeRetail.addEventListener('click', ()=>changePosition(-1));

    function changePosition(change) {

        const currentElementRetail = Number(document.querySelector('.slider-blog-retail__body--show').dataset.id);

        value = currentElementRetail;
        value+= change;

        console.log(sliderRetail.length);

        if(value === 0 || value == sliderRetail.length+1) {
            value = value === 0 ? sliderRetail.length : 1;
        }

        sliderRetail[currentElementRetail-1].classList.toggle('slider-blog-retail__body--show');
        sliderRetail[value-1].classList.toggle('slider-blog-retail__body--show');
        
    }
    
}) ();
